import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>

    <div className="py-16">
      <div className="text-center">
        <p className="text-sm font-semibold text-caffeinity-green-light uppercase tracking-wide">404 error</p>
        <h1 className="mt-2 text-4xl font-extrabold text-caffeinity-green-dark tracking-tight sm:text-5xl">Page not found.</h1>
        <p className="mt-2 text-base text-caffeinity-green-dark ">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-6">
          <Link to="/" className="text-base font-medium text-caffeinity-green-light hover:bg-caffeinity-brown">
            Go back home<span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
    </div>


  </Layout>
)

export default NotFoundPage
